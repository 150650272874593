<script>
const filteringLayouts = {
  tickets: defineAsyncComponent(() => import('~/features/tickets/filtering')),
};

export default defineNuxtComponent({
  name: 'FilteringMenu',
  props: {
    module: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    filteringLayout() {
      return filteringLayouts[this.module];
    },
  },
  methods: {
    filteringChanged(name, selectedOptions) {
      this.$emit('filteringChanged', name, selectedOptions);
    },
  },
});
</script>

<template>
  <div class="w-full bg-white">
    <component :is="filteringLayout" @filtering-changed="filteringChanged" />
  </div>
</template>
